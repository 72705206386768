import {
  CaseworkerRegistration,
  FinishedWeek,
  Registration,
  RegistrationForm,
  UserRegistrations,
} from '/@types/timer';
import { api, createQueryParams, genericApiErrorHandler } from '/@utilities/api';
import { dateToString, formatDateTime, getWeekDays } from '/@utilities/date';
import { defineStore, storeToRefs } from 'pinia';
import { RegistrationStatusIds } from '/@types/ids';
import { mergeMap } from '/@utilities/general';
import { populateRegistrationForm } from '/@utilities/registrations';
import { useUserStore } from './user';
import { getDay, isSameDay } from 'date-fns';
import { ref, computed } from 'vue';

const statusNames = new Map([
  [100, 'Opprettet'],
  [150, 'Avvist'],
  [160, 'Tilbakemelding gitt'],
  [200, 'Godkjent'],
  [300, 'Klar til overføring'],
  [320, 'Feil i overføring'],
  [350, 'Overført'],
  [400, 'Overført'],
]);

export function mapRegistrations(regs: Array<any>): Map<number, Registration> {
  return new Map(
    regs.map((reg: any) => [
      reg.Id,
      {
        articleId: reg.ArticleId,
        articleLabel: reg.ArticleLabel,
        articleName: reg.ArticleName,
        articleGroupName: reg.ProductGroupName,
        articleLabels: new Set(reg.ArticleLabels),

        guid: reg.Guid,
        id: reg.Id,
        userId: reg.UserId,

        date: new Date(reg.Date),
        fromDateTimeOffset: reg.FromDateTimeOffset,
        toDateTimeOffset: reg.ToDateTimeOffset,
        hours: reg.Hours,

        productGroupName: reg.ProductGroupName,

        description: reg.Description,

        notesCount: reg.NotesCount,
        notesUnresolvedCount: reg.NotesUnresolvedCount,

        projectCaseworker: reg.ProjectCaseworkerName,

        projectId: reg.ProjectId,
        projectName: reg.ProjectName,
        projectReferenceNo2: reg.ProjectReferenceNo2,

        statusId: reg.StatusId,
        statusName: statusNames.get(reg.StatusId) || reg.StatusName,

        activityId: reg.ActivityId,
        activityName: reg.ActivityName,

        approvedAt: reg.ApprovedAt ? new Date(reg.ApprovedAt) : null,
        approvedBy: reg.ApprovedBy,

        modifiedAt: reg.ModifiedAt ? new Date(reg.ModifiedAt) : null,
        modifiedBy: reg.ModifiedBy,

        isTime: reg.ArticleOptions.includes('time'),
        isDay: reg.ArticleUnit === 'dag',
        isExpense: reg.ProductGroupName === 'Utgifter',
        isCorrection: reg.Hours < 0,
        isLabel: reg.ArticleOptions.includes('label'),
        isLocked: reg.Locked || reg.StatusId >= RegistrationStatusIds.Approved,
        registrationLocked: !!reg.RegistrationsLockedAt,

        labels: new Set(reg.ArticleLabels),
        // correction used by elsmart integration
        correctionOfRegistrationId: reg.CorrectionOfRegistrationId,
        correctedByRegistrationId: reg.CorrectedByRegistrationId,
      },
    ]),
  );
}

function mapCaseworkerRegistration(reg: any): CaseworkerRegistration {
  function createDaysArray(days: any): any[] {
    const week = [[], [], [], [], [], [], []];

    days.forEach((day: any) => {
      week.splice(getDay(new Date(day.Date)) - 1, 1, day);
    });

    return week;
  }

  return {
    members: new Map(
      reg.map((mem: any) => [
        mem.Id,
        {
          userId: mem.Id,
          user: mem.Name,
          userDepartmentId: mem.UserDepartmentId,
          finishedWeek: mem.FinishedWeek,
          sumExpenses: mem.SumExpenses,
          sumExpensesApproved: mem.SumExpensesApproved,
          sumDays: mem.SumDays,
          sumDaysApproved: mem.SumDaysApproved,
          sumHours: mem.SumHours,
          sumHoursApproved: mem.SumHoursApproved,

          days: createDaysArray(mem.Days).map((day) => ({
            date: day.Date ? new Date(day.Date) : null,
            sumExpenses: day.SumExpenses,
            sumExpensesApproved: day.SumExpensesApproved,
            sumDays: day.SumDays,
            sumDaysApproved: day.SumDaysApproved,
            sumHours: day.SumHours,
            sumHoursApproved: day.SumHoursApproved,
            rejectedCount: day.RejectedRegistrations,
            feedbackCount: day.RegistrationsWithFeedback,

            allTimesatserApproved:
              day.SumDays === day.SumDaysApproved && day.SumHours === day.SumHoursApproved,
            allExpensesApproved: day.SumExpenses === day.SumExpensesApproved,
          })),
        },
      ]),
    ),
  };
}

interface ErrorRegistrations {
  projectId: number;
  project: string;
  statusId: number;
  status: string;
  article: string;
  userId: number;
  user: string;
  userDepartment: string;
  userDepartmentId: number;
  hours: number;
  date: Date;
  error: string | null;
}

function mapRegistrationsErrors(regs: any): Map<number, ErrorRegistrations> {
  const a = regs.Members.reduce((acc: any[], cv: any) => {
    acc.push(
      cv.Days.reduce((acc: any, cv: any) => {
        acc.push(
          cv.Registrations.map((reg: any) => {
            return [
              reg.Id,
              {
                projectId: reg.ProjectId,
                project: reg.ProjectName,
                statusId: reg.StatusId,
                status: statusNames.get(reg.StatusId),
                article: reg.ArticleName,
                userId: reg.UserId,
                user: reg.UserFullname,
                userDepartmentId: reg.UserDepartmentId,

                // Requires explicitly loading departments first
                userDepartment: reg.UserDepartmentId ? departments.value?.get(reg.UserDepartmentId) : "",
                hours: reg.Hours,
                date: new Date(reg.Date),
                error: reg.ErrorMessage,
              },
            ];
          }),
        );
        return acc;
      }, []),
    );
    return acc;
  }, []);
  return new Map(a.flat(2));
}

const departments = ref<Map<number, string>>(new Map());

function loadDepartments(): Promise<Map<number, string>> {
  return api
    .get(`/departments`)
    .then(({ data }) => {
      departments.value = new Map(data.map((d: any) => [d.Id, d.Name]));
      return departments.value;
    })
    .catch(genericApiErrorHandler);
}

function mapRegistrationForm(originalForm: RegistrationForm, changes = {}) {
  const form = {
    ...originalForm,
    ...changes,
  };

  const hasActivities = form.activities.length > 0;

  const coreMapper = () => {
    const activity = form.activities[0];
    return {
      Date: dateToString(new Date(form.date)),
      Hours: form.hours,
      ArticleId: form.articleId,
      ProjectId: form.projectId,
      Description: form.description,
      FromDateTime: formatDateTime(form.fromDateTime, new Date(form.date)),
      ToDateTime: formatDateTime(form.toDateTime, new Date(form.date)),
      RegistrationStatusId: RegistrationStatusIds.Created,
      ActivityId: null,
      UserId: form.userId,

      ...(hasActivities && {
        Hours: activity.hours,
        Description: activity.description,
        FromDateTime: formatDateTime(activity.fromDateTime, new Date(form.date)),
        ToDateTime: formatDateTime(activity.toDateTime, new Date(form.date)),
        ActivityId: activity.id,
      }),
    };
  };

  return form.activities.length > 1
    ? {
        Registrations: form.activities.map((a) => ({
          ...coreMapper(),
          FromDateTime: formatDateTime(a.fromDateTime, new Date(form.date)),
          ToDateTime: formatDateTime(a.toDateTime, new Date(form.date)),
          Hours: a.hours,
          ActivityId: a.id,
          Description: a.description,
        })),
      }
    : coreMapper();
}

function mapFinishedWeek(data: any): FinishedWeek | null {
  return data
    ? {
        deletedAt: data.DeletedAt,
        id: data.FinishedWeekId,
        year: data.IsoWeekYear,
        week: data.Week,
        setByUserId: data.UserId,
        setDate: new Date(data.SetFinished),
        days: data.Days || [],
      }
    : null;
}

export const useTimerStore = defineStore('timer', () => {
  const registrations = ref<Map<number, Registration>>(new Map());
  const registrationErrors = ref<Map<number, Registration>>(new Map());
  const caseworkerRegistration = ref<null | CaseworkerRegistration>(null);
  const errorRegistrations = ref<Map<number, ErrorRegistrations>>(new Map());
  const finishedWeek = ref<FinishedWeek | null>(null);

  const userRegistrations = computed(() => {
    const { user } = storeToRefs(useUserStore());
    return new Map([...registrations.value].filter(([id, reg]) => reg.userId === user.value?.id));
  });

  function loadRegistrations({
    fromDate,
    toDate,
    userId,
  }: {
    fromDate: string;
    toDate: string;
    userId?: string;
  }) {
    const queries = createQueryParams(
      new Map([
        ['date', fromDate],
        ['dateTo', toDate],
        ['userId', userId],
      ]),
    );

    return api
      .get(`timer/registrations?${queries}`)
      .then(({ data }) => {
        const regs = mapRegistrations(data);
        registrations.value = mergeMap(registrations.value, regs);
        return regs;
      })
      .catch(genericApiErrorHandler);
  }

  function loadRegistration(registrationId: number) {
    return api
      .get(`timer/registrations/${registrationId}`)
      .then(({ data }) => {
        registrations.value = mergeMap(registrations.value, mapRegistrations([data]));
      })
      .catch(genericApiErrorHandler);
  }

  function createRegistration(form: RegistrationForm) {
    if (form.articleIdCopy) {
      createRegistrationCopy(form);
    }

    return (
      form.activities.length > 1
        ? api.post(`timer/registrations/bulk`, mapRegistrationForm(form))
        : api.post(`timer/registrations`, mapRegistrationForm(form))
    ).catch(genericApiErrorHandler);
  }

  function updateRegistration(registrationId: number, form: RegistrationForm) {
    return api
      .patch(`timer/registrations/${registrationId}`, mapRegistrationForm(form))
      .catch(genericApiErrorHandler);
  }

  function deleteRegistration(registrationId: number) {
    return api
      .delete(`timer/registrations/${registrationId}`)
      .then(() => registrations.value.delete(registrationId))
      .catch(genericApiErrorHandler);
  }

  function createRegistrationCopy(form: RegistrationForm) {
    const formNew = {
      ...form,
      activities: [],
      articleId: form.articleIdCopy,
    };
    return api
      .post('timer/registrations', mapRegistrationForm(formNew))
      .catch(genericApiErrorHandler);
  }

  function createCorrection(registrationId: number) {
    return api
      .post(`timer/registrations/${registrationId}/correction`)
      .catch(genericApiErrorHandler);
  }

  function loadCaseworkerRegistrations({
    isoWeekNumber,
    isoWeekYear,
    caseworkerId,
    projectNameSearch,
  }: {
    isoWeekNumber: number;
    isoWeekYear: number;
    caseworkerId: number | null;
    projectNameSearch: string | null;
  }) {
    return api
      .get(`timer/registrations/caseworkers`, {
        params: {
          isoWeekNumber,
          isoWeekYear,
          caseworkerId: caseworkerId === 0 ? null : caseworkerId,
          projectNameSearch,
        },
      })
      .then(({ data }) => {
        caseworkerRegistration.value = mapCaseworkerRegistration(data);
      })
      .catch(genericApiErrorHandler);
  }

  function loadUserRegistrations({
    userId,
    isoWeekNumber,
    isoWeekYear,
    caseworkerId,
    projectNameSearch,
  }: {
    userId: number;
    isoWeekNumber: number;
    isoWeekYear: number;
    caseworkerId: number | null;
    projectNameSearch: string | null;
  }): Promise<void | Array<UserRegistrations>> {
    return api
      .get(`timer/registrations/user/${userId}`, {
        params: {
          isoWeekYear,
          isoWeekNumber,
          caseworkerId: caseworkerId === 0 ? null : caseworkerId,
          projectNameSearch,
        },
      })
      .then(({ data }) => {
        const weekDates = getWeekDays(isoWeekYear, isoWeekNumber);
        const regs = mapRegistrations(data);

        return weekDates.map((date) => {
          const registrations = [...regs].filter(([id, reg]) => isSameDay(reg.date, date));
          return {
            date: new Date(date),
            registrations: new Map(registrations),
            totalExpenses: registrations.reduce(
              (acc, cv) => acc + (cv[1].isExpense ? cv[1].hours : 0),
              0,
            ),
            totalHours: registrations.reduce(
              (acc, cv) => acc + (!cv[1].isExpense && !cv[1].isDay ? cv[1].hours : 0),
              0,
            ),
            totalDays: registrations.reduce((acc, cv) => acc + (cv[1].isDay ? cv[1].hours : 0), 0),
            allRegistrationsApproved:
              registrations.length > 0 && !registrations.some(([id, reg]) => reg.statusId < 200),
          };
        });
      })
      .catch(genericApiErrorHandler);
  }

  type RejectedRegistration = {
    id: number;
    projectId: number;
    projectName: string;
    articleName: string;
    activityName: string;
    hours: number;
    userId: number;
    notesCount: number;
    date: Date;
  };

  const rejectedRegistrations = ref<Map<number, RejectedRegistration>>(new Map());

  function loadRejectedRegistrations(userId: number) {
    return api
      .get(`timer/registrations/user/${userId}/rejected`)
      .then(({ data }) => {
        rejectedRegistrations.value = new Map(
          data.map((r: any) => [
            r.Id,
            {
              id: r.Id,
              projectId: r.ProjectId,
              projectName: r.ProjectName,
              articleName: r.ArticleName,
              hours: r.Hours,
              userId: r.UserId,
              notesCount: r.NotesCount,
              date: new Date(r.Date),
            },
          ]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  function loadFailedRegistrations() {
    return api
      .get('timer/registrations/transfererror')
      .then(({ data }) => {
        errorRegistrations.value = mergeMap(errorRegistrations.value, mapRegistrationsErrors(data));
      })
      .catch(genericApiErrorHandler);
  }

  function changeRegistrationStatus(regId: number, statusId: number) {
    return api
      .post(`timer/registrations/${regId}/status`, { StatusId: statusId })
      .then(() => {
        const reg = errorRegistrations.value.get(regId);
        if (!reg) return;
        reg.statusId = statusId;
        reg.status = statusNames.get(statusId);
        errorRegistrations.value.set(regId, reg);
      })
      .catch(genericApiErrorHandler);
  }

  function approveRegistrations(registrations: Array<any>) {
    return api
      .post(
        `timer/registrationstatus/approve`,
        registrations.map((r) => ({
          RegistrationId: r.registrationId,
          Hours: r.hours,
        })),
      )
      .catch(genericApiErrorHandler);
  }

  function removeApproval(registration: Registration) {
    const form = populateRegistrationForm(registration);
    return api
      .patch(`timer/registrations/${registration.id}`, mapRegistrationForm(form))
      .catch(genericApiErrorHandler);
  }

  function loadFinishedWeek(week: number, year: number) {
    return api
      .get(`timer/finishedweeks/${week}/${year}`)
      .then(({ data }) => {
        finishedWeek.value = mapFinishedWeek(data);
      })
      .catch(genericApiErrorHandler);
  }

  function createFinishedWeek(week: number, year: number, days: Array<number>) {
    return api
      .post(`timer/finishedweeks/${week}/${year}`, { Days: days })
      .then(() => loadFinishedWeek(week, year))
      .catch(genericApiErrorHandler);
  }

  function updateFinishedWeek(finishedWeekId: number, days: Array<number> | null) {
    return api
      .put(`timer/finishedweeks/${finishedWeekId}`, { Days: days })
      .catch(genericApiErrorHandler);
  }

  function deleteFinishedWeek(finishedWeekId: number) {
    return api.delete(`timer/finishedweeks/${finishedWeekId}`).catch(genericApiErrorHandler);
  }

  function sendRegistrationsToTransfer(weekNo: number, year: number) {
    return api
      .post(`timer/registrationstatus/readyfortransfer`, {
        WeekYear: year,
        WeekNo: weekNo,
      })
      .catch(genericApiErrorHandler);
  }

  function saveTotalWorkingHours(tenantId: number, workhours: number) {
    return api.put(`/Tenants/${tenantId}/TotalWorkingHours`, { TotalWorkingHours: workhours });
  }

  function loadTotalProjectHours(projectId: number): Promise<number> {
    return api
      .get(`/projects/${projectId}/registrations/sum`)
      .then(({ data }) => data.TotalHours)
      .catch(genericApiErrorHandler);
  }

  return {
    registrations,
    loadRegistration,
    loadRegistrations,
    createRegistration,
    updateRegistration,

    createCorrection,

    sendRegistrationsToTransfer,
    approveRegistrations,
    removeApproval,
    changeRegistrationStatus,
    deleteRegistration,

    userRegistrations,
    loadUserRegistrations,
    rejectedRegistrations,
    loadRejectedRegistrations,

    departments,
    loadDepartments,

    registrationErrors,
    errorRegistrations,
    loadFailedRegistrations,

    caseworkerRegistration,
    loadCaseworkerRegistrations,

    finishedWeek,
    loadFinishedWeek,
    createFinishedWeek,
    updateFinishedWeek,
    deleteFinishedWeek,

    loadTotalProjectHours,

    saveTotalWorkingHours,
  };
});
